import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import mockMapData from './mockData/mockMapData.json';
import Stack from '@mui/material/Stack';
import { Tooltip, Chip } from '@mui/material';

const USE_MOCK = process.env.REACT_APP_USE_DATA === 'mock' ? true : false;

function createEmployeeRowData(community, employees, matchesWithin500, total600m, commuteMatches) {
  return {
    community,
    total: employees.length,
    matchesWithin500,
    employees,
    total600m,
    commuteMatches
  };
}

function HeadingCell({ text }) {
  return (
    <TableCell align="center" sx={{ fontSize: '22px', fontWeight: 'bold' }}>
      {text}
    </TableCell>
  );
}

function BodyCell({ text }) {
  return (
    <TableCell align="center" sx={{ fontSize: '20px' }}>
      {text}
    </TableCell>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [hoverStates, setHoverStates] = React.useState({});

  React.useEffect(() => {
    const initialHoverStates = {};
    row.employees.forEach((emp) => {
      initialHoverStates[emp] = false;
    });
    setHoverStates(initialHoverStates);
  }, [row.employees]);

  const handleMouseEnter = (emp) => setHoverStates((prev) => ({ ...prev, [emp]: true }));
  const handleMouseLeave = (emp) => setHoverStates((prev) => ({ ...prev, [emp]: false }));

  const neighbours500 = props.neighbours500;
  const neighbours1000 = props.neighbours1000;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ fontSize: '20px' }}>{row.community}</TableCell>
        <BodyCell text={row.total} />
        <BodyCell text={row.matchesWithin500} />
        <BodyCell text={row.total600m} />
        <BodyCell text={row.commuteMatches} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Employees
              </Typography>
              <Stack direction="row" alignItems={'center'} spacing={1} useFlexGap flexWrap="wrap">
                {row.employees.map((empRow) => (
                  <Tooltip
                    key={empRow}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          border: 1,
                          bgcolor: 'white',
                          color: 'black',
                          '.MuiTooltip-arrow': {
                            color: 'white'
                          }
                        }
                      }
                    }}
                    title={
                      <React.Fragment>
                        <Box
                          display="flex"
                          flexDirection={'column'}
                          alignItems="center"
                          marginRight={2}
                        >
                          <Typography display={'flex'} flexDirection={'column'}>
                            Employees Within 500m:
                          </Typography>

                          <Box display={'flex'} flexDirection={'row'}>
                            {neighbours500[empRow] ? (
                              neighbours500[empRow].map((emp_id) => {
                                return (
                                  <Chip
                                    sx={{ marginRight: 1 }}
                                    label={emp_id}
                                    color="primary"
                                    variant="outlined"
                                  />
                                );
                              })
                            ) : (
                              <Typography>N/A</Typography>
                            )}{' '}
                          </Box>

                          <Typography display={'flex'} flexDirection={'column'}>
                            Employees within 1000m:
                          </Typography>
                          <Box display={'flex'} flexDirection={'row'}>
                            {neighbours1000[empRow] ? (
                              neighbours1000[empRow].map((emp_id) => {
                                return (
                                  <Chip
                                    sx={{ marginRight: 1 }}
                                    label={emp_id}
                                    color="primary"
                                    variant="outlined"
                                  />
                                );
                              })
                            ) : (
                              <Typography>N/A</Typography>
                            )}
                          </Box>
                        </Box>
                      </React.Fragment>
                    }
                    open={hoverStates[empRow]}
                    onOpen={() => {}}
                    onClose={() => {}}
                  >
                    <Chip
                      onMouseEnter={() => handleMouseEnter(empRow)}
                      onMouseLeave={() => handleMouseLeave(empRow)}
                      label={empRow}
                      color="primary"
                      variant={hoverStates[empRow] ? 'filled' : 'outlined'}
                    />
                  </Tooltip>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    community: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    employees: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
  }).isRequired
};

export default function EmployeeTable({ empData, employeeSummaryData, globalData }) {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    let data = null;
    if (!empData || empData.length === 0) {
      data = mockMapData.rows;
    } else {
      data = empData.rows;
    }

    // create dictionary using community as key and values are list of employees
    const byCommunity = data.reduce((acc, obj) => {
      const key = obj.community;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj.employee_id);
      return acc;
    }, {});

    let mockWithin600 = [115, 96, 84, 71, 60, 55, 78, 49, 34, 60];
    let mockTotalCommuter = [42, 30, 20, 18, 16, 17, 27, 15, 10, 20];
    let tempRows = Object.keys(byCommunity).map((key) => {
      const employeesWithin500 = employeeSummaryData.result[key]
        ? Math.ceil(employeeSummaryData.result[key][0] / 2)
        : 0;
      const total600m = globalData.result[key] ? Math.ceil(globalData.result[key][2]) : 0;
      const commuteMatches = globalData.result[key] ? Math.ceil(globalData.result[key][0] / 2) : 0;
      return createEmployeeRowData(
        key,
        byCommunity[key],
        employeesWithin500,
        total600m,
        commuteMatches
      );
    });

    tempRows.sort(({ total: a }, { total: b }) => b - a);

    if (USE_MOCK) {
      tempRows.forEach((row, index) => {
        row.total600m = mockWithin600[index];
        row.commuteMatches = mockTotalCommuter[index];
      });
    }
    setRows(tempRows);
  }, []);

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontSize: '22px', fontWeight: 'bold' }}>Community</TableCell>
            <HeadingCell text="Total Employees" />
            <HeadingCell text="Co-Workers Within 500m" />
            <HeadingCell text="Total Employees within 600m" />
            <HeadingCell text="Commuter Matches (<500m)" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={row.name}
              row={row}
              neighbours500={employeeSummaryData.neighbours500}
              neighbours1000={employeeSummaryData.neighbours1000}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
