import * as React from 'react';
import { Grid, TableBody } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const USE_MOCK = process.env.REACT_APP_USE_DATA === 'mock' ? true : false;

function preventDefault(event) {
  event.preventDefault();
}

function HeadingCell({ text, align = 'center' }) {
  return (
    <TableCell align={align} sx={{ fontSize: '22px', fontWeight: 'bold' }}>
      {text}
    </TableCell>
  );
}

function BodyCell({ text, align = 'center' }) {
  return (
    <TableCell align={align} sx={{ fontSize: '20px' }}>
      {text}
    </TableCell>
  );
}

export default function Summary({ data, companyName, commuteInfo, csvData, globalData }) {
  console.log('Summary data', commuteInfo);
  return (
    <Grid container spacing={2} padding={2}>
      <React.Fragment>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <HeadingCell align="left" text="Company Name" />
              <HeadingCell text="Employee Count" />
              <HeadingCell text="500m matches" />
              <HeadingCell text="Total Daily Commute Distance (KM)" />
              <HeadingCell text="Total Daily Commute Duration (hours)" />
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <BodyCell align="left" text={companyName} />
              <BodyCell text={csvData.rows.length} />
              <BodyCell text={data.total500} />
              <BodyCell
                text={Math.round(parseFloat(csvData.totalDailyDistance)).toLocaleString()}
              />
              <BodyCell
                text={Math.round(parseFloat(csvData.totalDailyCommuteTime)).toLocaleString()}
              />
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <BodyCell align="left" text="600m Office" />
              <BodyCell text={USE_MOCK ? '4,500' : globalData.totalEmployees.to} />
              <BodyCell text={USE_MOCK ? '815' : globalData.globalTotal500} />
              <BodyCell
                text={USE_MOCK ? '141,390 ' : parseFloat(commuteInfo.totalDistance).toFixed()}
              />
              <BodyCell text={USE_MOCK ? '2,700' : commuteInfo.totalDuration.toFixed()} />
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    </Grid>
  );
}
