import { axiosInstance } from './InitAxios';

export const login = (username, password) => {
  console.log('email isssss:', username);
  const data = {
    username,
    password
  };

  return axiosInstance
    .post('login', data, {
      withCredentials: true
    })
    .then((response) => {
      // response body is user object
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const sessionCheck = async () => {
  try {
    const res = await axiosInstance.get('/api/session-check', {
      withCredentials: true
    });
    console.log(res);
    return res;
  } catch (error) {
    console.error('Error checking authentication', error);
    return false;
  }
};

export const signup = (firstName, lastName, email, password, organization) => {
  const data = {
    firstName,
    lastName,
    email,
    password,
    organization
  };

  return axiosInstance
    .post('user/signup', data, {
      withCredentials: true
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const logout = () => {
  return axiosInstance
    .post('logout', {}, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
