import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Report from './pages/Report';
import LandingPage from './pages/Landing';
import { sessionCheck } from './api/Auth';
import ProtectedRoute from './components/common/ProtectedRoute';

function App() {
  const [user, setUser] = useState(null);
  const [csvID, setCSVID] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const response = await sessionCheck();
        setAuthenticated(response.data.loggedIn);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error checking authentication', error);
      } finally {
        setIsLoading(false); // Set loading to false after the check
      }
    };

    verifyAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading or a spinner
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<SignIn setUser={setUser} setAuthenticated={setAuthenticated} />}
        />
        <Route
          path="/signin"
          element={<SignIn setUser={setUser} setAuthenticated={setAuthenticated} />}
        />
        <Route path="/signup" element={<SignUp setUser={setUser} />} />
        <Route
          path="/landing"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              component={LandingPage}
              user={user}
              setCSVID={setCSVID}
              setCompanyName={setCompanyName}
            />
          }
        />
        <Route
          path="/report"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              component={Report}
              user={user}
              csvID={csvID}
              companyName={companyName}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
