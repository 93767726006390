import axios from 'axios';

const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_BACKEND_URL
    : 'http://localhost:8000/';

export const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});
