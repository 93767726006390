import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';
import Map from '../components/Report/Map';
import EmployeeTable from '../components/Report/EmployeeTable';
import AppBarComponent from '../components/common/AppBar';
import Summary from '../components/Report/Summary';
import { CircularProgress } from '@mui/material';
import { getComputeDistance, getGlobalComputeDistance, getAllCommuteInfo } from '../api/Csv';
import Copyright from '../components/common/Copyright';
import { Navigate, useNavigate } from 'react-router-dom';
import { getCSV } from '../api/Csv';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Report({ user, csvID, companyName }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [summaryLoaded, setSummaryLoaded] = React.useState(false);
  const [globalSummaryLoaded, setGlobalSummaryLoaded] = React.useState(false);
  const [summaryData, setSummaryData] = React.useState(null);
  const [globalSummaryData, setGlobalSummaryData] = React.useState(null);
  const [csvData, setCSVData] = React.useState(null);
  const [csvLoaded, setCSVLoaded] = React.useState(false);
  const [commuteInfo, setCommuteInfo] = React.useState(null);
  const [commuteInfoLoaded, setCommuteInfoLoaded] = React.useState(false);

  if (!csvID) {
    csvID = user.latestCsvFileId;
  }

  React.useEffect(() => {
    getComputeDistance(csvID).then((response) => {
      setSummaryData(response);
      setSummaryLoaded(true);
    });
    getAllCommuteInfo().then((response) => {
      setCommuteInfo(response);
      setCommuteInfoLoaded(true);
    });
    getCSV(csvID).then((response) => {
      setCSVData(response);
      setCSVLoaded(true);
    });
    getGlobalComputeDistance(csvID).then((response) => {
      setGlobalSummaryData(response);
      setGlobalSummaryLoaded(true);
    });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBarComponent
        position="absolute"
        title="Employee Mobility Management Report"
        showPrint={true}
      />
      <CssBaseline />
      <Box sx={{ mb: 4, p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
              {csvLoaded ? <Map mapData={csvData} /> : <CircularProgress />}
              {/* <Map /> */}
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 200,
                alignItems: 'center'
              }}
            >
              {summaryLoaded && csvLoaded && globalSummaryLoaded && commuteInfoLoaded ? (
                <Summary
                  data={summaryData}
                  companyName={companyName}
                  commuteInfo={{
                    totalDistance: commuteInfo.distanceList.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b),
                      0
                    ),
                    totalDuration: commuteInfo.durationList.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b),
                      0
                    )
                  }}
                  csvData={csvData}
                  globalData={globalSummaryData}
                />
              ) : (
                <CircularProgress />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {summaryLoaded && csvLoaded && globalSummaryLoaded ? (
                <EmployeeTable
                  empData={csvData}
                  employeeSummaryData={summaryData}
                  globalData={globalSummaryData}
                />
              ) : (
                <CircularProgress />
              )}
              {/* <EmployeeTable  /> */}
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Box>
    </ThemeProvider>
  );
}
