import React, { useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import mockMapData from './mockData/mockMapData.json';
import useSupercluster from 'use-supercluster';
import { IconButton, Typography } from '@mui/material';
import Room from '@mui/icons-material/Room';
import BusinessIcon from '@mui/icons-material/Business';

const G_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Marker = ({ children }) => children;

export default function Map({ mapData }) {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  const data = mapData.rows || mockMapData.rows;
  const officeCoords = [
    parseFloat(mapData['office_longitude']),
    parseFloat(mapData['office_latitude'])
  ];
  const points = data.map((item) => ({
    type: 'Feature',
    properties: { cluster: false, id: item.id },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: G_MAP_API_KEY }}
        defaultCenter={{ lat: 51.0697638, lng: -113.9799363 }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <Marker key={cluster.id} lat={latitude} lng={longitude}>
                <IconButton>
                  <Room style={{ color: 'red' }} />
                  <Typography variant="button" color={'red'} thickness={'bold'}>
                    {pointCount}
                  </Typography>
                </IconButton>
              </Marker>
            );
          }

          return (
            <Marker key={cluster.properties.id} lat={latitude} lng={longitude}>
              <IconButton>
                <Room style={{ color: 'red' }} />
              </IconButton>
            </Marker>
          );
        })}
        <Marker lat={officeCoords[1]} lng={officeCoords[0]}>
          <IconButton>
            <BusinessIcon style={{ color: 'blue' }} />
          </IconButton>
        </Marker>
      </GoogleMapReact>
    </div>
  );
}
