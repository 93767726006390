import React from 'react';
import { AppBar, hexToRgb } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import logo from '../../assets/logo.png';
import { logout } from '../../api/Auth';
import { useNavigate } from 'react-router-dom';
import HelveticaNeueBold from '../../assets/fonts/HelveticaNeueBold.ttf';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function AppBarComponent({ title = 'E.M.M.A', showPrint = false }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then((response) => {
        navigate('/signin', { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrint = (event) => {
    event.preventDefault();
    window.print();
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'HelveticaNeue'
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'HelveticaNeue';
            font-style: normal;
            font-display: swap;
            font-weight: 550;
            src: local('HelveticaNeue'), local('HelveticaNeue-Bold'), url(${HelveticaNeueBold}) format('ttf');
          }
        `
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <IconButton>
              <img src={logo} alt="logo" style={{ height: 46, width: 128 }} />
            </IconButton>
          </Typography>
          <Typography
            variant="h3"
            sx={{ flexGrow: 1, color: '#49C7EE', fontFamily: 'HelveticaNeue', fontWeight: 600 }}
          >
            {title}
          </Typography>
          {showPrint && (
            <Button href="#" variant="outlined" sx={{ my: 1, mx: 1 }} onClick={handlePrint}>
              Print
            </Button>
          )}
          <Button href="#" variant="outlined" sx={{ my: 1 }} onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
