import { axiosInstance } from './InitAxios';

export const getCSV = (csvID) => {
  return axiosInstance
    .get(`csv/${csvID}`, {
      withCredentials: true
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const uploadCSV = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance
    .post('csv', formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const getCSVFileUploadProgress = (csvID) => {
  return axiosInstance
    .get(`csv/${csvID}`, {
      withCredentials: true
    })
    .then((response) => {
      return response.data.uploadProgress;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getComputeDistance = (csvID) => {
  return axiosInstance
    .get(`csv/computeDistance/${csvID}`, {
      withCredentials: true
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGlobalComputeDistance = (csvID) => {
  return axiosInstance
    .get(`csv/computeGlobalDistance/${csvID}`, {
      withCredentials: true
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAllCommuteInfo = () => {
  return axiosInstance
    .get('/getAllCommuteInfo', {
      withCredentials: true
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
