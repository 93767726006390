import * as React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { uploadCSV, getCSV, getCSVFileUploadProgress } from '../api/Csv';
import AppBarComponent from '../components/common/AppBar';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Paper from '@mui/material/Paper';
import Template from '../assets/Template.xlsx';
import VisibilityIcon from '@mui/icons-material/Visibility';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const classes = {
  rootContainer: {
    display: 'flex', // use flexbox to center children
    justifyContent: 'center', // center horizontally
    alignItems: 'center' // center vertically
  },
  uploadButton: {
    color: 'black',
    borderColor: 'black',
    marginRight: 2
  },
  viewButton: {
    color: 'black',
    borderColor: 'black'
  },
  progressBox: {
    width: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 5
  },
  errorBox: {
    width: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    height: '50%',
    borderColor: 'black',
    borderRadius: 25,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default function LandingPage({ user, setCSVID, setCompanyName }) {
  const [file, setFile] = React.useState(null);
  const [generating, setGenerating] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState();
  const navigate = useNavigate();
  let csvID = null;
  let companyName = null;

  const handleSubmit = (uploadFile) => {
    uploadCSV(uploadFile)
      .then((response) => {
        csvID = response.csvID;
        companyName = response.companyName;
        const interval = setInterval(() => {
          getCSVFileUploadProgress(response.csvID).then((newProgress) => {
            console.log('new progress: ', newProgress);
            setProgress(newProgress);
            if (Math.floor(newProgress) === 1) {
              console.log('finished uploading');
              clearInterval(interval);
              setCSVID(csvID);
              setCompanyName(companyName);
              navigate('/report');
            }
          });
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        setGenerating(false);
        setFile(null);
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          setError(error.response.data.message);
        } else {
          // Fallback error message
          setError('An unexpected error occurred.');
        }
      });
  };

  const viewLatestReport = () => {
    getCSV(user.latestCsvFileId).then((response) => {
      console.log('csv data: ', response);
      setCSVID(user.latestCsvFileId);
      setCompanyName(response.company_name);
      navigate('/report');
    });
  };

  const handleDownload = () => {
    window.open(Template);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBarComponent />
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          fontFamily="Helvetica Neue"
          variant="h4"
          align="center"
          color="text.secondary"
          gutterBottom
        >
          Employee Mobility Management Application
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Analyze your data and generate insighful reports with just one click.
        </Typography>
      </Container>
      {/* End hero unit */}
      {/* for container create spa */}
      <Container maxWidth="md" component="main" sx={classes.rootContainer}>
        <Button
          variant="outlined"
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={classes.uploadButton}
        >
          Upload Employee Data
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={(e) => {
              setFile(e.target.files[0]);
              setGenerating(true);
              handleSubmit(e.target.files[0]);
            }}
          />
        </Button>
        {user.latestCsvFileId !== 0 ? (
          <Button
            variant="outlined"
            component="label"
            startIcon={<VisibilityIcon />}
            sx={classes.viewButton}
            onClick={viewLatestReport}
          >
            View Latest Report
          </Button>
        ) : (
          <div />
        )}
      </Container>
      {error && (
        <Box sx={classes.errorBox}>
          <Typography align="center" color="text.secondary">
            {error}
          </Typography>
        </Box>
      )}
      {generating ? (
        <Box sx={classes.progressBox}>
          <Typography align="center" color="text.secondary">
            Processing...
          </Typography>
          <LinearProgress variant="determinate" value={progress * 100} />
        </Box>
      ) : (
        <div />
      )}

      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        alignItems="center"
        justifyContent="center"
        sx={{ pt: 8, pb: 6 }}
      >
        <Paper elevation={5} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5" gutterBottom>
            Don't have a CSV file?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Download our template and add your employee data into it.
          </Typography>
          <Container
            sx={{
              pt: 2
            }}
          >
            <div align="center">
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudDownloadIcon />}
                style={{
                  color: 'black',
                  borderColor: 'black'
                }}
                onClick={handleDownload}
              >
                Download template
              </Button>
            </div>
          </Container>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
